#nav-bar {
    min-height: 10vh !important;
}

#nav-bar #advanced-logo {
    min-height: 30px;
    max-height: 8vh !important;
}

@media screen and (max-width: 768px) {
    #nav-bar {
        padding: 0 !important;
        height: unset !important;
    }

    #nav-bar #advanced-logo {
        max-width: 50%;
    }

    #nav-bar #collapsed-items {
        justify-content: space-between;
        align-items: flex-start !important;
    }

    #nav-bar #nav-bar-collapse ul {
        margin: 0 !important;
    }

    #nav-bar #options {
        flex-direction: column;
        row-gap: 1.5rem;
    }

    #nav-bar #options-default {
        justify-content: space-between;
        margin-bottom: 10px;
    }

    #dark-mode-toggler {
        font-size: 1.5rem !important;
        padding-right: 5px;
    }
    
    #dark-mode-toggler svg {
        height: 1.5rem;
    }

    #dark-mode-toggler .form-switch {
        position: relative;
        align-items: center !important;
        top: -1px;
    }
}