#card-page {
    min-height: 100vh;
}

#card-page #card-page-content {
    width: 75%;
    padding-top: 10vh;
}

#card-page-content #photo {
    max-width: 300px;
}

#card-page-content #socials-container .social-media-icon {
    flex: 0 0 25%;
}

@media screen and (max-width: 768px) {
    #card-page {
        padding-top: 0 !important;
    }
    
    #card-page #card-page-content {
        width: 100% !important;
    }

    #card-page-content #row-top {
        padding-top: 20px;
        justify-content: space-between !important; 
    }

    #card-page-content #col-photo {
        width: 30%
    }

    #card-page-content #col-info {
        order: 2;
        width: 70%;
        margin-bottom: 20px;
    }

    #card-page-content #photo {
        max-width: 100%;
        height: auto;
        object-fit: cover;
    }

    #card-page-content .contact-button {
        width: 75% !important;
        margin-bottom: 10px;
    }

    #socials-container {
        justify-content: space-between;
    }

    #socials-container .social-media-icon {
        flex: 0 0 33.333% !important;
    }
}