#home-page #image-top {
    height: 90vh;
    margin-top: 10vh !important;
    background-color: rgba(0, 0, 0, 0.50);
    background-blend-mode: darken;
    background-size: cover;
}

#home-page .tiny {
    font-size: 0.75rem;
}

#home-page .anchor {
    position: absolute;
    top: -10vh;
    left:0px
}

@media screen and (max-width: 768px) {
    #home-page .section-title {
        font-size: 2rem !important;
    }
    #home-page #about-us {
        font-size: 1.25rem !important;
    }
    #home-page #bottom-email {
        font-size: 1.25rem;
    }
    
    /*
        CAROUSEL
    */

    #carousel-home .bottom-sm {
        bottom: -10px !important;
    }
    #carousel-home .bottom-sm-large {
        bottom: -45px !important;
    }
    #carousel-home .text-content {
        min-height: 50vh !important;
    }
    #carousel-home .text-content-large {
        min-height: 60vh !important;
    }
    .margin-bottom-mobile {
        margin-bottom: 30px;
    }
}