body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.prevent-select {
  -webkit-user-select: none;  
  -moz-user-select: none;    
  -ms-user-select: none;      
  user-select: none;
}

.no-focus:focus {
  box-shadow: none !important;
}

.fit-content {
  width: fit-content !important;
}

.no-min-width {
  min-width: unset !important;
}

.under-navbar{ 
  padding-top: 10vh;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3); ;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}