/*
    CAROUSEL
*/
#packages-carousel .carousel-indicators {
    bottom: -3rem;
}

#packages-carousel .carousel-indicators button {
    background-color: var(--bs-primary-dark);
    height: 5px;
    width: 3.2rem;
}

.carousel-dark .carousel-indicators button {
    background-color: var(--bs-primary) !important;
}

/*
    MARKETING PLANS
*/
#marketing-plans .marketing-plan {
    height: 80vh;
}

#marketing-plans .marketing-image {
    width: 80%;
    aspect-ratio: 1/1;
    object-fit: contain;
}

/*
    PACKAGE INFO
*/
@media screen and (max-width: 768px) {
    #marketing-plans .marketing-plan {
        height: unset;
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 2rem;
    }
    
    #marketing-plans .marketing-image {
        width: 85%;
    }

    #package-info #title-list {
        padding: 0;
    }

    #package-info #title {
        font-size: 1.5rem !important;
        margin: 0 auto !important;
    }

    #col-title {
        text-align: center !important;
    }
}